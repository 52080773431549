import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  TOAST_KEY = 'global-toast';
  STICKY = false;

  constructor(private messageService: MessageService) {}

  async success(detail: string): Promise<void> {
    this.showToast('Success', detail, 'success');
  }

  async info(detail: string): Promise<void> {
    this.showToast('Information', detail, 'info');
  }

  async warn(detail: string): Promise<void> {
    this.showToast('Warning', detail, 'warn');
  }

  async error(error: any): Promise<void> {
    let detail = 'Unknown error ocurred';
    if (error instanceof HttpErrorResponse) {
      detail = (error as HttpErrorResponse).message;
    }
    if (typeof error === 'string') {
      detail = error.toString();
    }
    this.showToast('Error', detail, 'error');
  }

  async showToast(
    summary: string,
    detail: string,
    severity: string
  ): Promise<void> {
    this.messageService.add({
      key: this.TOAST_KEY,
      severity: severity,
      summary: summary,
      detail: detail,
      sticky: this.STICKY,
    });
  }
}
