export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '6b47b6b2-0b0d-4b2a-9146-9307794a8d4e',
      authority:
        'https://login.microsoftonline.com/fe16105c-2cd4-4447-b05f-440cb233041a',
    },
  },
  apiConfig: {
    uri: 'api://6b47b6b2-0b0d-4b2a-9146-9307794a8d4e/.default',
    scopes: ['api://6b47b6b2-0b0d-4b2a-9146-9307794a8d4e/RBAC_Read'],
  },
  rbacAPIConfig: {
    uri: 'https://rbac.dev.udtsp.net/api',
    scopes: ['api://6b47b6b2-0b0d-4b2a-9146-9307794a8d4e/RBAC_Read'],
    defaultPageSize: 10,
  },
};
